import {
  Product1Laptop,
  Product2Laptop,
  Product3Laptop,
  Product4Laptop,
  Product5Laptop,
  Product6Laptop,
  Product7Laptop,
  Product8Laptop,
} from "assets/images";

export const ProductsData = [
  {
    id: 1,
    title: "Ip yigiruv",
    desc:
      "ipyigiruv-desc",
    pic: Product5Laptop,
    text:
      "ipyigiruv-text",
  },
  {
    id: 2,
    title: "Gap yo'q yog'i",
    desc:
      "yog-desc",
    pic: Product1Laptop,
    text: "yog-text",
  },
  {
    id: 3,
    title: "️Paxtani qayta ishlash",
    desc:
      "paxta-desc",
    pic: Product3Laptop,
    text: "paxta-text"
  },
  {
    id: 4,
    title: "Qishloq xo’jalik",
    desc:
      "qishloq-desc",
    pic: Product4Laptop,
    text: "qishloq-text"
  },
  {
    id: 5,
    title: "Tikuvchilik",
    desc:
      "tikuvchilik-desc",
    pic: Product8Laptop,
    text: "tikuvchilik-text"
  },
  {
    id: 6,
    title: "Chorvachilik kompleksi",
    desc:
      "chorvachilik-desc",
    pic: Product2Laptop,
    text: "chorvachilik-text"
  },
  {
    id: 7,
    title: "Bo’yoq",
    desc:
      "boyoq-desc",
    pic: Product7Laptop,
    text: "boyoq-text"
  },
  {
    id: 8,
    title: "To’quv",
    desc:
      "toquv-desc",
    pic: Product6Laptop,
    text: "toquv-text"
  },
];
